import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/focusNews",
    name: "focusNews",
    component: () => import("../views/FocusNews.vue"),
  },
  {
    path: "/video",
    name: "videoPage",
    component: () => import("../views/VideoPage.vue"),
  },
  {
    path: "/channel",
    name: "channel",
    component: () => import("../views/Channel.vue"),
  },
  {
    path: "/special",
    name: "special",
    component: () => import("../views/Special.vue"),
  },

  {
    path: "/textPageDetail",
    name: "textPageDetail",
    component: () => import("../views/TextPageDetail.vue"),
  },
  {
    path: "/videoPageDetail",
    name: "videoPageDetail",
    component: () => import("../views/VideoPageDetail.vue"),
  },
  {
    path: "/specialDetail",
    name: "specialDetail",
    component: () => import("../views/SpecialDetail.vue"),
  },
  {
    path: "/specialTagDetail",
    name: "specialTagDetail",
    component: () => import("../views/SpecialTagDetail.vue"),
  },
  {
    path: "/searchList",
    name: "searchList",
    component: () => import("../views/SearchList.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
