<template>
  <div class="playing">
    <div class="playing-item"></div>
    <div class="playing-item"></div>
    <div class="playing-item"></div>
    <div class="playing-item"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.playing {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 20px;

  &-item {
    width: 3px;
    height: 8px;
    background: $primary-color;
    border-radius: 2px 2px 0 0;
    transform-origin: 50% 100%;

    &:nth-child(1) {
      animation: loading 0.4s ease 0s infinite;
    }

    &:nth-child(2) {
      animation: loading 0.4s linear 0.1s infinite;
    }

    &:nth-child(3) {
      animation: loading 0.4s linear 0.2s infinite;
    }

    &:nth-child(4) {
      animation: loading 0.4s linear 0.3s infinite;
    }

    @keyframes loading {
      0% {
        transform: scaleY(1);
      }

      10% {
        transform: scaleY(1.1);
      }

      20% {
        transform: scaleY(1.2);
      }

      30% {
        transform: scaleY(1.3);
      }

      50% {
        transform: scaleY(1.4);
      }

      60% {
        transform: scaleY(1.3);
      }

      70% {
        transform: scaleY(1.2);
      }

      80% {
        transform: scaleY(1.1);
      }

      90% {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(0.9);
      }
    }
  }
}
</style>
