const filters = {
  fixNumToBig(val) {
    if (!val) {
      return 0;
    } else if (val > 10000) {
      return (val / 10000).toFixed(2) + "万";
    }
    return val;
  },
  formatMsgTime(dateTimeStamp) {
    if (!dateTimeStamp) {
      return;
    }
    // 时间字符串转时间戳 获取毫秒数
    let timestamp = new Date(dateTimeStamp.replace(/-/g, "/")).getTime();
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let halfamonth = day * 15;
    let month = day * 30;
    let year = day * 365;
    let now = new Date().getTime();
    let diffValue = now - timestamp;
    let result;
    if (diffValue < 0) {
      return;
    }
    let yearC = diffValue / year;
    let monthC = diffValue / month;
    let weekC = diffValue / (7 * day);
    let dayC = diffValue / day;
    let hourC = diffValue / hour;
    let minC = diffValue / minute;
    if (yearC >= 1) {
      result = "" + parseInt(yearC) + "年前";
    } else if (monthC >= 1) {
      result = "" + parseInt(monthC) + "月前";
    } else if (weekC >= 1) {
      result = "" + parseInt(weekC) + "周前";
    } else if (dayC >= 1) {
      result = "" + parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
      result = "" + parseInt(hourC) + "小时前";
    } else if (minC >= 1) {
      result = "" + parseInt(minC) + "分钟前";
    } else result = "刚刚";
    return result;
  },
};

export default filters;
