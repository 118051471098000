<template>
  <div class="home" ref="home">
    <!-- <Header /> -->
    <div class="content">
      <SmallBanner style="margin-top: 5px" :data="homeBannerFirstObj" v-if="homeBannerFirstObj" />
      <div class="carousel">
        <MyCarousel :data="homeSwiperList" />
      </div>
      <div class="panel">
        <div class="item-title">
          <div class="title">
            <div class="solid"></div>
            要闻 <span class="sub-title">热点资讯</span>
          </div>
          <div class="more" @click="handleClickFocusNewsMore">查看更多</div>
        </div>
        <div class="panel-content">
          <div class="left"><ColumnVideo :data="newFocusColumn" /></div>
          <div class="right">
            <RowVideo
              class="right-item"
              v-for="(item, index) in newFocusRow"
              :data="item"
              :key="index"
            />
          </div>
        </div>
      </div>
      <SmallBanner
        style="margin-top: 20px"
        :data="homeBannerSecondObj"
        v-if="homeBannerSecondObj"
      />
      <div class="panel">
        <div class="item-title">
          <div class="title">
            <div class="solid"></div>
            视频 <span class="sub-title">精彩视频</span>
          </div>
          <div class="more" @click="handleClickVideoMore">查看更多</div>
        </div>
        <div class="panel-content">
          <div class="left"><ColumnVideo :data="videoFirstColumn" /></div>
          <div class="right">
            <ColumnVideo :data="videoSecondColumn" />
            <div class="row-wrapper">
              <RowVideo
                class="right-item"
                v-for="(item, index) in homeVideoRow"
                :data="item"
                :key="index"
              />
            </div>
          </div>
        </div>
      </div>
      <SmallBanner style="margin-top: 20px" :data="homeBannerThirdObj" v-if="homeBannerThirdObj" />
      <div class="home-channel">
        <div class="item-title">
          <div class="title">
            <div class="solid"></div>
            频道 <span class="sub-title">电视直播</span>
          </div>
          <div class="more" @click="handleClickChannelMore">查看更多</div>
        </div>
        <div class="channel-content" v-if="channelList && channelList.length">
          <div class="left-side">
            <div class="img-wrapper" @click="handleClickChannel">
              <img class="bg-img" :src="homeChannelLeftRow.operatePictures" alt="" />
              <img class="play-img" src="@/assets/image/play.png" alt="" />
            </div>
            <div class="content-wrapper">
              <div class="title text-line-1">
                {{ homeChannelLeftRow.operateName }}
              </div>
              <div class="content-info">
                <div>
                  {{ homeChannelLeftRow.updateTime | formatMsgTime }}
                  {{ homeChannelLeftRow.publishName }}
                </div>
                <div>播放 {{ homeChannelLeftRow.clickSum }}</div>
              </div>
            </div>
          </div>
          <div class="right-side">
            <RowVideo
              class="right-item"
              v-for="(item, index) in homeChannelRightRow"
              :data="item"
              :key="index"
            />
          </div>
        </div>
      </div>
      <SmallBanner style="margin-top: 20px" :data="homeBannerFourObj" v-if="homeBannerFourObj" />
      <div class="panel">
        <div class="item-title">
          <div class="title">
            <div class="solid"></div>
            专题 <span class="sub-title">专题报道</span>
          </div>
          <div class="more" @click="handleClickSpecialMore()">查看更多</div>
        </div>
        <div class="special-content">
          <RowVideo
            class="special-item"
            v-for="(item, index) in homeSpecial"
            :key="index"
            :data="item"
          />
        </div>
      </div>
      <!-- <SmallBanner style="margin-top: 20px" :data="homeBannerFiveObj" /> -->
      <div class="panel" v-if="homeNotice && homeNotice.length">
        <div class="item-title">
          <div class="title">
            <div class="solid"></div>
            公告 <span class="sub-title">公告</span>
          </div>
          <div class="more">
            <!-- <a href="https://gzbn.gztv.com/#/special" target="_blank">查看更多</a> -->
          </div>
        </div>
        <div class="special-content">
          <RowVideo
            class="special-item"
            v-for="(item, index) in homeNotice"
            :key="index"
            :data="item"
          />
        </div>
      </div>

      <!-- <div
        id="admove"
        class="admove"
        :style="{
          top: admoveTop + 'px',
          left: admoveLeft + 'px',
        }"
        ref="admove"
        @mouseenter="handleAdmoveMouseEnter"
        @mouseleave="handleAdmoveMouseLeave"
      >
        <div>
          <div class="tit">
            <h1>生态广州数据</h1>
            <span @click="handleAdmoveClose">关闭窗口 X </span>
          </div>
          <div class="adcon">
            <a href="http://59.41.9.54/webapp/anion/index.html#/anionBigScreen " target="_blank"
              ><img
                src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0613/f68d32dce89243ecaeb16279c8691ee71407584946854400.jpg"
                title="空气负（氧）离子浓度"
            /></a>

            <a href="http://112.94.69.56:8059/eco-web/#/kqzlzkw " target="_blank"
              ><img
                src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0613/749fc6fb11874c209bd31fdc3a2d947d1407584957075712.jpg"
                title="PM2.5"
            /></a>
            <a href="http://59.41.9.54/webapp/anion/index.html#/pollenScreen " target="_blank"
              ><img
                src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0613/57e8604f3fd44341918fa6bb3472584f1407584966261248.jpg"
                title="花粉指数"
            /></a>
            <a href="https://www.gz.gov.cn/attachment/7/7610/7610341/9673800.jpg" target="_blank"
              ><img
                src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0613/84af5694de9344a3a833268f871ea8af1407584978366720.png"
                title="赏花地图"
            /></a>
          </div>
        </div>
        
      </div> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import SmallBanner from "@/components/SmallBanner.vue";
import ColumnVideo from "@/components/ColumnVideo.vue";
import RowVideo from "@/components/RowVideo.vue";
import MyCarousel from "@/components/MyCarousel.vue";
import { queryChannelList, queryTabTemplateList } from "@/api/template";
import ChannelVue from "./Channel.vue";
import events from "@/utils/call.js";
import { countClickContent } from "@/api/template";
import { AdMove } from "@/utils/ad-piaochuang";

export default {
  name: "HomeView",
  components: {
    Header,
    SmallBanner,
    ColumnVideo,
    RowVideo,
    MyCarousel,
  },
  data() {
    return {
      homeBannerFirstObj: {},
      homeBannerSecondObj: {},
      homeBannerThirdObj: {},
      homeBannerFourObj: {},
      homeBannerFiveObj: {},
      homeSwiperList: [],
      newFocusColumn: {},
      newFocusRow: [],
      videoFirstColumn: {},
      videoSecondColumn: {},
      homeVideoRow: [],
      homeChannelLeftRow: {},
      homeChannelRightRow: [],
      homeSpecial: [],
      channelList: [],
      homeNotice: [],
      admoveTop: 0,
      admoveLeft: 0,
    };
  },
  mounted() {
    this.getHomeBanner();
    this.getHomeSwiper();
    this.getNewsFocus();
    this.getSecondHomeBanner();
    this.getHomeVideo();
    this.getThirdHomeBanner();
    this.getHomeChannel();
    this.getFourHomeBanner();
    this.getHomeSpecial();
    this.getHomeNotice();
    this.$store.commit("SET_TITLE_INDEX", 0);
    console.log("mounted");
    this.admoveTop = 300;
    this.admoveLeft = 500;
    // this.admoveTop = 100;
    // this.admoveLeft = this.$refs.home.clientWidth - 300;
    // console.log(this.admoveLeft);

    // let status = sessionStorage.getItem("admoveShow");
    // if (status != 2) {
    //   let ad = new AdMove("admove");
    //   ad.Run();
    // } else {
    //   this.$refs.admove.style.visibility = "hidden";
    // }

    // let intervalId = setInterval(() => {
    //   try {
    //     if (this.data.codeTime > 0) {
    //       let codeTime = this.data.codeTime - 1;
    //       this.setData({ codeTime: codeTime });
    //     } else {
    //       this.setData({ codeButtonShow: true, codeTime: 60 });
    //       clearInterval(intervalId);
    //     }
    //   } catch (err) {}
    // }, 1000);
  },
  methods: {
    getHomeBanner() {
      queryTabTemplateList({
        templateId: 1,
      }).then((res) => {
        this.homeBannerFirstObj = res.data.records.length ? res.data.records[0] : {};
        // this.homeBannerSecondObj = res.data.records.length ? res.data.records[1] : {};
        // this.homeBannerThirdObj = res.data.records.length ? res.data.records[2] : {};
        // this.homeBannerFourObj = res.data.records.length ? res.data.records[3] : {};
        // this.homeBannerFiveObj = res.data.records.length ? res.data.records[4] : {};
      });
    },
    getSecondHomeBanner() {
      queryTabTemplateList({
        templateId: 4,
      }).then((res) => {
        // console.log("resres", res.data.records[1]);
        this.homeBannerSecondObj = res.data.records[0];
      });
    },
    getThirdHomeBanner() {
      queryTabTemplateList({
        templateId: 6,
      }).then((res) => {
        this.homeBannerThirdObj = res.data.records[0];
      });
    },
    getFourHomeBanner() {
      queryTabTemplateList({
        templateId: 8,
      }).then((res) => {
        this.homeBannerFourObj = res.data.records[0];
      });
    },

    getHomeSwiper() {
      queryTabTemplateList({
        templateId: 2,
      }).then((res) => {
        this.homeSwiperList = res.data.records;
        console.log("this.homeSwiperList", this.homeSwiperList);
      });
    },
    getNewsFocus() {
      queryTabTemplateList({
        templateId: 3,
      }).then((res) => {
        let list = res.data.records.splice(0, 5);
        this.newFocusColumn = list.length ? list[0] : {};
        if (list.length) {
          list.forEach((item, index) => {
            if (index != 0) {
              this.newFocusRow.push(item);
            }
          });
        }
      });
    },
    getHomeVideo() {
      queryTabTemplateList({
        templateId: 5,
      }).then((res) => {
        let list = res.data.records.splice(0, 4);

        this.videoFirstColumn = list.length ? list[0] : {};
        this.videoSecondColumn = list.length ? list[1] : {};
        if (list.length) {
          list.forEach((item, index) => {
            if (index != 0 && index != 1) {
              this.homeVideoRow.push(item);
            }
          });
        }
      });
    },
    getHomeChannel() {
      queryTabTemplateList({
        templateId: 7,
      }).then((res) => {
        this.channelList = res.data.records.splice(0, 3);
        this.homeChannelLeftRow = this.channelList.length ? this.channelList[0] : {};
        if (this.channelList.length) {
          this.channelList.forEach((item, index) => {
            if (index != 0) {
              this.homeChannelRightRow.push(item);
            }
          });
        }
      });
    },
    getHomeSpecial() {
      queryTabTemplateList({
        templateId: 9,
      }).then((res) => {
        this.homeSpecial = res.data.records;
      });
    },

    handleClickChannel() {
      // window.open("https://www.gztv.com/#/channel");
      // console.log("this.homeChannelLeftRow", this.homeChannelLeftRow);
      countClickContent({
        operateId: this.homeChannelLeftRow.operateId,
        operateType: this.homeChannelLeftRow.operateType,
      }).then((res) => {
        console.log("channel click");
      });
      sessionStorage.setItem("channelOperateId", this.homeChannelLeftRow.operateId);
      window.open(`${window.location.protocol}//${window.location.host}/#/channel`);
    },

    getHomeNotice() {
      queryTabTemplateList({
        templateId: 12,
      }).then((res) => {
        this.homeNotice = res.data.records;
      });
    },

    handleClickSpecialMore() {
      // window.open("https://gzbn.gztv.com/#/special");
      // window.open("http://localhost:8080/#/special");
      // sessionStorage.setItem("titleIndex", 1);
      window.open(`${window.location.protocol}//${window.location.host}/#/special`);
    },
    handleClickChannelMore() {
      // window.open("https://gzbn.gztv.com/#/channel");
      window.open(`${window.location.protocol}//${window.location.host}/#/channel`);
    },

    handleClickVideoMore() {
      // window.open("https://gzbn.gztv.com/#/video");
      window.open(`${window.location.protocol}//${window.location.host}/#/video`);
    },

    handleClickFocusNewsMore() {
      // window.open("https://gzbn.gztv.com/#/focusNews");
      window.open(`${window.location.protocol}//${window.location.host}/#/focusNews`);
    },
    handleAdmoveClose() {
      console.log("handleAdmoveClose");
      this.$refs.admove.style.visibility = "hidden";
      sessionStorage.setItem("admoveShow", 2);
      //vuex SessionStorage
    },
    handleAdmoveMouseEnter() {},
    handleAdmoveMouseLeave() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";

.home {
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;

    .carousel {
      width: 1200px;
      height: 672px;
      // margin: 5px 0;
    }

    .panel {
      width: 1200px;
      margin-top: 50px;

      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #e9e9e9;

        .title {
          display: flex;
          align-items: flex-end;
          font-size: 20px;

          .solid {
            width: 4px;
            height: 20px;
            background: #e73828;
            border-radius: 4px;
            margin-right: 5px;
          }

          .set-top {
            color: $primary-color;
            margin-left: 10px;
            font-size: 16px;
          }
        }

        .more {
          font-size: 14px;
          color: #999;
          cursor: pointer;

          &:hover {
            color: #e73828;
          }
        }
      }

      .special-content {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;

        .special-item {
          margin-top: 20px;
          &:nth-child(n) {
            margin-right: 24px;
          }
          &:nth-child(2n) {
            margin-right: 24px;
          }
          &:nth-child(3n) {
            margin-right: 0px;
          }
        }
      }

      .panel-content {
        display: flex;
        margin-top: 20px;

        .left {
          margin-right: 24px;
        }

        .right {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;

          .row-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .right-item {
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }

              &:nth-last-child(2) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .home-channel {
      margin-top: 20px;
      width: 1200px;

      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #e9e9e9;

        .title {
          display: flex;
          align-items: flex-end;
          font-size: 20px;

          .solid {
            width: 4px;
            height: 20px;
            background: #e73828;
            border-radius: 4px;
            margin-right: 5px;
          }

          .set-top {
            color: $primary-color;
            margin-left: 10px;
            font-size: 16px;
          }
        }

        .more {
          font-size: 14px;
          color: #999;
          cursor: pointer;

          &:hover {
            color: #e73828;
          }
        }
      }

      .channel-content {
        display: flex;
        margin-top: 20px;

        .left-side {
          width: 792px;
          height: 572px;
          margin-right: 20px;
          border-radius: 4px;
          overflow: hidden;

          .img-wrapper {
            position: relative;
            width: 100%;
            height: 514px;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            &:hover {
              .bg-img {
                transform: scale(1.1);
              }
            }

            .bg-img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              background: #000;
              transition: transform 0.3s ease-in-out;
            }

            .play-img {
              position: absolute;
              top: 40%;
              left: 50%;
              transform: translateX(-50%);
              width: 30px;
              height: 30px;
              z-index: 9;
            }
          }

          .content-wrapper {
            font-size: 20px;
            height: 58px;

            .title {
              margin-bottom: 10px;
              cursor: pointer;

              &:hover {
                color: $primary-color;
              }
            }

            .content-info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #999;
              font-size: 14px;
            }
          }
        }

        .right-side {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 384px;
          height: 572px;
        }
      }
    }
    position: relative;
    .admove {
      // box-sizing: border-box;
      position: absolute;
      // top: 300px;
      // left: 600px;
      z-index: 999;
      width: 260px;
      .tit {
        background: url(https://load.gztv.com/mediadata/gzbn/product/src/2024/0613/2c2f5b4e62a24c79b68197c9ecf3e5ab1407588418357760.jpg)
          center left repeat-x;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 10px;
        h1 {
          font-size: 20px;
          color: #fff;
          font-weight: bold;
        }
        span {
          // position: absolute;
          right: 10px;
          bottom: 15px;
          color: #fff;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .adcon {
        // box-sizing: border-box;
        // width: 100%;
        border: 4px #fff solid;
        border-top: none !important;
        border-bottom: none !important;
        background: #fff;
        img {
          // border: 2px #fff solid;
          border-top: 2px #fff solid;
          border-bottom: 2px #fff solid;
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .sub-title {
    color: #666;
    font-size: 14px;
    margin-left: 10px;
  }
}
</style>
