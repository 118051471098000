<template>
  <div class="column-video" @click="handleClickItem(data)" v-if="data">
    <div class="bg-img-wrapper">
      <img class="bg-img" :src="data.operatePictures" alt="" />
    </div>
    <img class="play-img" src="@/assets/image/play.png" alt="" />
    <!-- <div class="video-info">
      <div class="title text-line-2">
        {{
          data.operateName
            ? data.operateName
            : data.articleTitle
            ? data.articleTitle
            : data.operateTitle
        }}
      </div>
      <div class="info">
        <div>
          {{ data.updateTime | formatMsgTime }}
          <span style="margin-left: 10px">
            <i class="el-icon-success" style="color: #e73828; margin-right: 5px"></i
            >{{ data.publishName }}
          </span>
        </div> -->
    <!-- <div>播放 {{ data.clickSum | fixNumToBig }}</div> -->
    <!-- </div> -->
    <!-- </div> -->

    <div class="video-info">
      <div class="title">
        {{
          data.operateName
            ? data.operateName
            : data.articleTitle
            ? data.articleTitle
            : data.operateTitle
        }}
      </div>
      <div class="bottom-info">
        <div>{{ data.updateTime | formatMsgTime }}</div>

        <span style="margin-left: 10px">
          <i class="el-icon-success" style="color: #e73828; margin-right: 5px"></i
          >{{ data.publishName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { countClickContent } from "@/api/template";
export default {
  name: "ColumnVideo",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleClickItem(item) {
      console.log("handleClickItem", item);
      countClickContent({ operateId: item.operateId, operateType: item.operateType }).then(
        (res) => {
          console.log("column");
        }
      );
      if (item.operateType == 1) {
        // 图文
        this.$router.push({
          name: "textPageDetail",
          query: {
            id: item.operateId,
          },
        });
      } else if (item.operateType == 2) {
        // 视频
        this.$router.push({
          name: "videoPageDetail",
          query: {
            id: item.operateId,
          },
        });
      }
    },
  },
  mounted() {
    // console.log("this.$props.data", this.$props.data);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.column-video {
  position: relative;
  width: 384px;
  height: 572px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);

  .bg-img-wrapper {
    width: 384px;
    height: 400px;
    overflow: hidden;
    &:hover {
      .bg-img {
        transform: scale(1.1);
      }

      // .play-img {
      //   width: 35px;
      //   height: 35px;
      // }
    }
    .bg-img {
      // width: 100%;
      // height: 100%;
      width: 384px;
      height: 400px;
      transition: transform 0.3s ease-in-out;
      // &:hover {
      //   transform: scale(1.1);
      // }
    }

    // .bg-img-wrapper:hover {
    //   .play-img {
    //     width: 35px;
    //     height: 35px;
    //   }
    // }
  }

  .play-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;

    transition: all 0.3s ease-in-out;
  }

  // .video-info {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   box-sizing: border-box;
  //   padding: 50px 12px 12px 12px;
  //   background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 5%, rgba(0, 0, 0, 0));

  //   .title {
  //     font-size: 14px;
  //     color: #fff;
  //     margin-bottom: 10px;
  //     text-indent: 1px;
  //     letter-spacing: 1px;
  //     line-height: 20px;
  //   }

  //   .info {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     color: #ccc;
  //     font-size: 12px;
  //   }
  // }

  .video-info {
    box-sizing: border-box;
    padding: 25px 10px;
    width: 384px;
    height: 172px;
    .title {
      text-overflow: ellipsis;
      text-indent: 1px;
      font-size: 16px;
      margin-bottom: 10px;
      letter-spacing: 1px;
      line-height: 20px;
      height: 100px;
      word-wrap: break-word;
      overflow: hidden;

      &:hover {
        color: $primary-color;
      }
    }
    .bottom-info {
      color: #999999;
      font-size: 14px;
      align-items: center;
      display: flex;
      // justify-content: space-between;
      // margin-bottom: 10px;
      // position: relative;
      // bottom: 0;
    }
  }
}
</style>
