<template>
  <div id="app">
    <Header />
    <div class="router-content">
      <keep-alive :include="['home', 'focusNews', 'videoPage', 'special']">
        <router-view />
      </keep-alive>
    </div>
    <Bottom style="height: 330px" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Bottom from "@/components/Bottom.vue";

export default {
  components: {
    Header,
    Bottom,
  },
};

// if (
//   navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i)
// ) {
//   window.location.href =
//     "https://app.gztv.com/filedata/app/PLUSApp/downloadPage/plus_download.html";
//   // console.log("手机访问");
// } else {
//   // console.log("电脑访问");
// }
</script>

<style lang="scss">
@media screen and (min-width: 738px) {
  body,
  html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }

  .router-content {
    display: flex;
    justify-content: center;
    // 536px
    min-height: calc(100vh - 410px);
  }
}

@media screen and (max-width: 738px) {
  body {
    // width: 100rem;
    width: 76rem;

    // width: 66rem;

    //zoom: 30%;
    // transform: scale(1);

    .channel {
      .content-wrapper {
        width: 52rem !important;
        // 56
      }
    }
  }
}
</style>
