import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    titleIndex: 0,
  },
  getters: {},
  mutations: {
    SET_TITLE_INDEX: (state, val) => {
      state.titleIndex = val;
    },
  },
  actions: {},
  modules: {},
});
