<template>
  <div class="bottom">
    <div class="content">
      <div class="info">
        <div>
          <div>联系方式</div>
          <div>
            <div>违法和不良信息举报</div>
            <div>{{ iphone }}</div>
          </div>
          <div>
            <div>举报邮箱</div>
            <div>{{ email }}</div>
          </div>
        </div>
        <div>
          <div>相关许可</div>
          <div>
            <div>信息网络传播视听节目许可证</div>
            <div>{{ licence }}</div>
          </div>
          <div>
            <div>互联网新闻信息服务许可证</div>
            <div>{{ licence2 }}</div>
          </div>
        </div>

        <!-- <div>
          <div>友情链接</div>
          <div href="https://www.12377.cn/" target="_blank">
            <a href="https://www.12377.cn/" target="_blank">中央网信办违法和不良信息举报中心</a>
          </div>
          <div>
            <a href="https://new.12377.cn/node_548446.htm" target="_blank">
              “网络举报”移动客户端
            </a>
          </div>
          <div>
            <a href="http://biaozhi.conac.cn/" target="_blank">
              全国党政机关事业单位-互联网网站标识管理服务平台
            </a>
          </div>
        </div> -->
        <div>
          <div>友情链接</div>
          <div><a href="http://www.cctv.com/" target="_blank">中央电视台</a></div>
          <div><a href="http://www.gdtv.com.cn/" target="_blank" id="gd">广东电视台</a></div>
        </div>

        <div class="bottom-qrcode">
          <!-- <img
            src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0521/413ee2edb76645af8313b3cea68451671405972309092352.jpg"
            alt=""
            style="width: 150px; height: 150px"
          /> -->
          <el-image
            style="width: 130px; height: 130px; margin-right: 50px"
            src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0521/413ee2edb76645af8313b3cea68451671405972309092352.jpg"
            alt=""
            preview-teleported
            :preview-src-list="[
              'https://load.gztv.com/mediadata/gzbn/product/src/2024/0521/413ee2edb76645af8313b3cea68451671405972309092352.jpg',
            ]"
            fit="cover"
          >
          </el-image>
          <div style="font-size: 20px; font-weight: bold; color: rgb(0, 0, 0)">揾记者 来有料</div>
        </div>

        <!-- <div>
          <div>关于我们</div>
          <div><a href="http://mail.gztv.com/" target="_blank">电子邮局</a></div>
        </div> -->
      </div>
      <div class="line"></div>
      <div class="about">
        <!-- https://gzbn.gztv.com:7443/plus/#/searchList -->
        <div style="margin-left: 33rem">
          <!-- <a href="https://gzbn.gztv.com/#/about" target="_blank">关于我们</a> -->
          <a style="cursor: pointer" @click="handleClickAbout">关于我们</a>
        </div>
        <div style="margin-left: 20px">
          <a href="http://mail.gztv.com/" target="_blank">电子邮箱</a>
        </div>
      </div>
      <div class="end">
        <div style="margin-left: 185px">
          ©2003-2023 广州广播电视台 .
          <span @click="handleClickICP(1)" class="icp-font">粤ICP备05093461号-1</span>

          <!-- <span @click="handleClickICP(2)" class="icp-font">粤ICP备05093461号-11</span> -->
        </div>
        <div>技术支持：广州市广播电视台</div>
        <!-- <img src="@/assets/image/badge.png" alt="" /> -->
        <div style="margin-left: 40px">
          <img src="@/assets/image/badge.png" alt="" />粤公网安备44010402003073号
        </div>
      </div>
      <div class="end-badge">
        <a href="http://www.12377.cn/" target="_blank"
          ><img
            src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/dc153b471ce447ac990bef64078831a11397537134954496.png"
        /></a>
        <a href="http://www.12377.cn/node_548446.htm" target="_blank"
          ><img
            src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/7fd0dafd796c4904a7652e65725b5ba11397537135213312.png"
        /></a>
        <a
          href="https://bszs.conac.cn/sitename?method=show&id=0AC8833E8BAF0EE9E053012819AC7074"
          target="_blank"
          ><img
            id="imgConac"
            vspace="0"
            hspace="0"
            border="0"
            src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/e9b2918ac3c549d8bf8bb67a1aa7008a1397537134970880.png"
            data-bd-imgshare-binded="1"
        /></a>
        <a href="http://www.gzjd.gov.cn/gsmpro/web/serviceitem/web_alert_view.html" target="_blank"
          ><img
            src="https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/thumb/34f60533bf0246c285487e05c5a3c5f01397537135237888.jpg"
            style="height: 20px"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
      iphone: "(020)89213303",
      email: "guangshiwang@gztv.com",
      licence: "1904061",
      licence2: "44120190004",
    };
  },
  methods: {
    handleClickAbout() {
      window.open(`${window.location.protocol}//${window.location.host}/#/about`);
    },
    handleClickICP(num) {
      if (num == 1) {
        window.open("https://beian.miit.gov.cn/");
      } else if (num == 2) {
        window.open("https://beian.miit.gov.cn/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";

.bottom {
  width: 100%;
  // height: 336px;
  background: rgba(239, 240, 248, 1);
  // margin-top: 60px;
  overflow: hidden;
}

.content {
  width: 1200px;
  margin: 0 auto;
}

.info {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 158px;
  text-align: left;
}

.info > div {
  height: 100%;
}

.info > div > div:nth-child(1) {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-weight: 600;
}

.info > div:not(:last-child) > div:not(:first-child) > div:nth-child(2) {
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  margin-top: 4px;
}

.info > div:nth-child(3) > div:nth-child(n + 2),
.info > div:not(:last-child) > div:not(:first-child) > div:nth-child(1) {
  margin-top: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.about {
  display: flex;
  align-items: center;

  // justify-content: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-top: 10px;
}

.end {
  // margin-top: 20px;
  margin-top: 5px;
  display: flex;
  //justify-content: center;

  align-items: center;
  height: 17px;
  font-size: 12px;
  color: rgba(8, 14, 48, 0.45);
}

.end-badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.end > div:nth-child(2) {
  margin-left: 40px;
}

.line {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);

  &:hover {
    color: #e73828;
  }
}

.icp-font {
  // margin-left: 10px;
  cursor: pointer;
  &:hover {
    color: #008cff;
  }
}

.bottom-qrcode {
}
</style>
