import { get, post, postForm } from "@/http/request";

// 查询模板内容
export function queryTabTemplateList(params) {
  return get(`/plus-cloud-manage-app/webPage/queryTemplateContentList`, params)
    .then((res) => res.data)
    .catch((e) => e);
}

// 查询图文详情
export function getManuDetail(id) {
  return get(`/gzbn-cloud-manuscript-app/app/article/plusDetail/${id}`)
    .then((res) => res.data)
    .catch((e) => e);
}

// 查询视频
export function getVideoById(params) {
  return get(`/plus-cloud-manage-app/video/getVideoById`, params)
    .then((res) => res.data)
    .catch((e) => e);
}

// 查询专题展示信息
export function getTopicInfo(params) {
  return get(`/plus-cloud-manage-app/topic/queryTopicInfoByTemplate`, params)
    .then((res) => res.data)
    .catch((e) => e);
}

// 查询专题信息
export function getTopicData(params) {
  return get(`/plus-cloud-manage-app/topic/queryTopicById`, params)
    .then((res) => res.data)
    .catch((e) => e);
}

// 查询专题二级分类
export function queryTopicByTopicTag(params) {
  return get(`/plus-cloud-manage-app/topic/queryTopicByTopicTag`, params)
    .then((res) => res.data)
    .catch((e) => e);
}

// 查询频道列表
export function queryChannelList(params) {
  return get(`/plus-cloud-manage-app/liveChannel/queryLiveChannelList`, params)
    .then((res) => res.data)
    .catch((e) => e);
}

//统计点击
export function countClickContent(data) {
  return post(`/plus-cloud-manage-app/web/content/click/click`, data)
    .then((res) => res.data)
    .catch((e) => e);
}
