<template>
  <div class="banner" v-if="data && data.operatePictures">
    <img class="banner-img" :src="data.operatePictures" alt="" />
  </div>
</template>

<script>
export default {
  name: "smallBanner",
  props: {
    data: {
      type: Object,
      // default: () => {
      //   return {
      //     operatePictures:
      //       "https://load.gztv.com/filedata/gzbn/product/src/2023/0510/7ccb70b0f9214108b63bddd5fac175c81379311229763584.jpg",
      //   };
      // },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.banner {
  width: 1200px;
  height: 80px;
  overflow: hidden;

  .banner-img {
    width: 100%;
    height: 80px;
    border-radius: 2px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }
}
</style>
