<template>
  <div class="channel">
    <div class="tab">
      <span :class="['live', liveBroadcastType === 1 && 'living']" @click="changeLiveType(1)"
        >电视直播</span
      >
      <span :class="['live', liveBroadcastType === 2 && 'living']" @click="changeLiveType(2)"
        >广播直播</span
      >
    </div>
    <div class="content-wrapper">
      <div class="video-wrapper">
        <video
          playsinline
          webkit-playsinline
          controls
          id="videoPlayer"
          ref="videoRef"
          :poster="videoPoster"
          class="video-class"
        ></video>
      </div>
      <!-- :class="['channel-item', expandIndex !== index && 'inactive']" -->
      <div class="program-wrapper">
        <div :class="['channel-item']" v-for="(item, index) in channelList" :key="index">
          <div
            :class="['channel-title', expandIndex === index && 'title-active']"
            @click="handleClickTitle($event, item, index)"
          >
            <!-- @mousedown="handleMouseDownTitle($event, item, index)" -->
            <!-- @touchstart="handleTouchStartTitle($event, item, index)" -->
            {{ item.name }}
          </div>
          <PlayingIcon class="playing-icon-class" v-if="expandIndex == index" />
        </div>
      </div>
    </div>

    <!-- <SmallBanner style="margin-top: 60px" /> -->
  </div>
</template>

<script>
import Hls from "hls.js";
import SmallBanner from "@/components/SmallBanner";
import PlayingIcon from "@/components/PlayingIcon";
import { queryChannelList } from "@/api/template";
import events from "@/utils/call.js";
import { nextTick } from "vue";
// import VConsole from "vconsole";

export default {
  name: "Channel",
  components: {
    SmallBanner,
    PlayingIcon,
  },
  data() {
    return {
      liveBroadcastType: 1,
      expandIndex: 0,
      curPlayIdx: 0,
      channelList: [],
      hls: null,
      videoPoster: null,
      eventDisable: false,
    };
  },
  mounted() {
    // this.$store.commit("SET_TITLE_INDEX", 3);

    events.$emit("headerTab", "频道");
    this.getData();
  },
  beforeDestroy() {
    this.hls.destroy();
    this.hls = null;
    this.videoRef = null;
  },
  methods: {
    getData() {
      queryChannelList({ type: 1 }).then((res) => {
        console.log(res);
        this.channelList = res.data;
        // console.log("aaaaaaa", this.channelList, this.expandIndex);
        let operateId = sessionStorage.getItem("channelOperateId");
        console.log("channelOperateId", operateId);
        if (operateId == null) {
          this.videoPoster = this.channelList[this.expandIndex].pictureUrl;
          this.getStream(this.channelList[this.expandIndex].httpUrl);
        } else {
          let one = this.channelList.findIndex((item) => item.id == operateId);
          if (one < 0) {
            this.videoPoster = this.channelList[this.expandIndex].pictureUrl;
            this.getStream(this.channelList[this.expandIndex].httpUrl);
          } else {
            this.expandIndex = one;
            this.videoPoster = this.channelList[this.expandIndex].pictureUrl;
            this.getStream(this.channelList[this.expandIndex].httpUrl);
          }
        }
      });
    },
    changeLiveType(type) {
      // let vConsole = new VConsole();
      // console.log("hahahahaha111");
      // alert("切换");
      if (type === this.liveBroadcastType) {
        return;
      }
      this.expandIndex = 0;
      // console.log("前");
      if (
        !navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        this.hls.destroy();
      }

      // console.log("后");
      this.hls = null;
      this.videoRef = null;
      this.liveBroadcastType = type;
      if (type == 1) {
        this.$refs.videoRef.controls = true;
        this.getData();
      } else {
        this.$refs.videoRef.controls = false;
        queryChannelList({ type: 2 }).then((res) => {
          console.log(res);
          this.channelList = res.data;
          this.videoPoster = this.channelList[this.expandIndex].pictureUrl;
          console.log(this.videoPoster);
          this.getStream(this.channelList[this.expandIndex].httpUrl);
        });
      }
    },
    getStream(source) {
      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(source);
        this.hls.attachMedia(this.$refs.videoRef);

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // console.log("加载成功");
          this.$refs.videoRef.play();
        });
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          // console.log("error");
          console.log(event, data);
        });
      } else if (this.$refs.videoRef.canPlayType("application/x-mpegURL")) {
        console.log("c");
        this.$refs.videoRef.src = source;
        console.log("d");
        this.$refs.videoRef.addEventListener("loadedmetadata", () => {
          console.log("e");
          this.$refs.videoRef.play();
          console.log("f");
          // if (status) {
          //   this.$refs.videoRef.play();
          // } else {
          //   this.$refs.videoRef.pause();
          // }
        });
      }
    },
    handleClickTitle(e, item, index) {
      // console.log("click", e, this.eventDisable);
      // alert("触发");
      // let vConsole = new VConsole();
      if (index == this.expandIndex) {
        return;
      }
      console.log("a");
      if (
        !navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        this.hls.destroy();
      }
      console.log("b");
      this.hls = null;
      this.videoRef = null;
      this.expandIndex = index;
      this.getStream(item.httpUrl);

      this.videoPoster = item.pictureUrl;
      // if (
      //   navigator.userAgent.match(
      //     /(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i
      //   )
      // ) {
      //   document.getElementById("gd").style.color = "red";
      // }
    },
    /*   handleMouseDownTitle(e, item, index) {
      console.log("mousedown", e);
      this.eventDisable = true;
      // e.stopPropagation();
      // e.preventDefault();
      // document.ontouchstart
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        document.getElementById("gd").style.color = "blue";
      }
    },
     */
    /*     handleTouchStartTitle(e, item, index) {
      console.log("touchstart", e);

      // if (
      //   navigator.userAgent.match(
      //     /(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i
      //   )
      // ) {
      document.getElementById("gd").style.color = "green";
      // }
    }, */
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.channel {
  width: 1200px;
  // box-sizing: border-box;
  // padding-top: 20px;
  // padding-bottom: 120px;

  margin-top: 20px;
  padding-bottom: 60px;

  .tab {
    display: flex;
    align-items: center;

    .live {
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
      text-indent: 1px;
      letter-spacing: 1px;

      &:first-child {
        margin-right: 24px;
      }
    }

    .living {
      font-weight: bold;
      color: #000;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 528px;
    margin-top: 18px;

    .video-wrapper {
      width: 940px;
      height: 100%;
      background: #eee;
      border-radius: 4px;
      overflow: hidden;

      .video-class {
        width: 100%;
        height: 100%;
      }
    }

    .program-wrapper {
      width: 256px;
      height: 100%;
      background: rgba(239, 240, 248, 1);
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 0 12px;
      border-radius: 4px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: transparent;
      }

      .channel-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: #000;
        font-size: 16px;
        height: 50px;
        border-bottom: 1px solid #f9f9f9;

        .channel-title {
          font-size: 16px;
          cursor: pointer;
          &:hover {
            color: #2993f0 !important;
          }
        }

        .title-active {
          font-size: 20px;
          font-weight: bold;
        }
      }

      .inactive {
        display: flex;
        align-items: center;
        height: 50px;
      }
    }
  }
}
</style>
