import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import filters from "@/utils/filters";
import Pagination from "@/components/Pagination";

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.component("Pagination", Pagination);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
