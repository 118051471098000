var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"info"},[_c('div',[_c('div',[_vm._v("联系方式")]),_c('div',[_c('div',[_vm._v("违法和不良信息举报")]),_c('div',[_vm._v(_vm._s(_vm.iphone))])]),_c('div',[_c('div',[_vm._v("举报邮箱")]),_c('div',[_vm._v(_vm._s(_vm.email))])])]),_c('div',[_c('div',[_vm._v("相关许可")]),_c('div',[_c('div',[_vm._v("信息网络传播视听节目许可证")]),_c('div',[_vm._v(_vm._s(_vm.licence))])]),_c('div',[_c('div',[_vm._v("互联网新闻信息服务许可证")]),_c('div',[_vm._v(_vm._s(_vm.licence2))])])]),_vm._m(0),_c('div',{staticClass:"bottom-qrcode"},[_c('el-image',{staticStyle:{"width":"130px","height":"130px","margin-right":"50px"},attrs:{"src":"https://load.gztv.com/mediadata/gzbn/product/src/2024/0521/413ee2edb76645af8313b3cea68451671405972309092352.jpg","alt":"","preview-teleported":"","preview-src-list":[
            'https://load.gztv.com/mediadata/gzbn/product/src/2024/0521/413ee2edb76645af8313b3cea68451671405972309092352.jpg',
          ],"fit":"cover"}}),_c('div',{staticStyle:{"font-size":"20px","font-weight":"bold","color":"rgb(0, 0, 0)"}},[_vm._v("揾记者 来有料")])],1)]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"about"},[_c('div',{staticStyle:{"margin-left":"33rem"}},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.handleClickAbout}},[_vm._v("关于我们")])]),_vm._m(1)]),_c('div',{staticClass:"end"},[_c('div',{staticStyle:{"margin-left":"185px"}},[_vm._v(" ©2003-2023 广州广播电视台 . "),_c('span',{staticClass:"icp-font",on:{"click":function($event){return _vm.handleClickICP(1)}}},[_vm._v("粤ICP备05093461号-1")])]),_c('div',[_vm._v("技术支持：广州市广播电视台")]),_vm._m(2)]),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("友情链接")]),_c('div',[_c('a',{attrs:{"href":"http://www.cctv.com/","target":"_blank"}},[_vm._v("中央电视台")])]),_c('div',[_c('a',{attrs:{"href":"http://www.gdtv.com.cn/","target":"_blank","id":"gd"}},[_vm._v("广东电视台")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"20px"}},[_c('a',{attrs:{"href":"http://mail.gztv.com/","target":"_blank"}},[_vm._v("电子邮箱")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"40px"}},[_c('img',{attrs:{"src":require("@/assets/image/badge.png"),"alt":""}}),_vm._v("粤公网安备44010402003073号 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"end-badge"},[_c('a',{attrs:{"href":"http://www.12377.cn/","target":"_blank"}},[_c('img',{attrs:{"src":"https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/dc153b471ce447ac990bef64078831a11397537134954496.png"}})]),_c('a',{attrs:{"href":"http://www.12377.cn/node_548446.htm","target":"_blank"}},[_c('img',{attrs:{"src":"https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/7fd0dafd796c4904a7652e65725b5ba11397537135213312.png"}})]),_c('a',{attrs:{"href":"https://bszs.conac.cn/sitename?method=show&id=0AC8833E8BAF0EE9E053012819AC7074","target":"_blank"}},[_c('img',{attrs:{"id":"imgConac","vspace":"0","hspace":"0","border":"0","src":"https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/e9b2918ac3c549d8bf8bb67a1aa7008a1397537134970880.png","data-bd-imgshare-binded":"1"}})]),_c('a',{attrs:{"href":"http://www.gzjd.gov.cn/gsmpro/web/serviceitem/web_alert_view.html","target":"_blank"}},[_c('img',{staticStyle:{"height":"20px"},attrs:{"src":"https://load.gztv.com/mediadata/gzbn/product/src/2024/0123/thumb/34f60533bf0246c285487e05c5a3c5f01397537135237888.jpg"}})])])
}]

export { render, staticRenderFns }