<template>
  <div class="header">
    <div class="header-wrapper">
      <img
        class="logo"
        src="https://gzbn.gztv.com/filedata/default/gswicon.png"
        alt="logo"
        @click="handleClikcToHome"
      />
      <!-- <img class="logo" src="~@/assets/image/logo.png" alt="logo" @click="handleClikcToHome" /> -->
      <div class="nav">
        <div
          v-for="(item, index) in titles"
          :key="index"
          :class="[titleIndex == index && 'nav-active', 'nav-item']"
          @click="handleClickTab(item, index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="search-box">
        <input
          type="text"
          size="14"
          class="input-box"
          v-model="searchValue"
          @keydown.enter="handleSearchContent"
        />
        <i class="el-icon-search btn-box" @click="handleSearchContent"></i>
      </div>
      <div class="right-pane">
        <el-popover placement="bottom" width="200" trigger="click">
          <div class="button-wrapper">
            <div class="download-btn" @click="handleClickPlusSharePage">花城+</div>
            <div class="download-btn" @click="handleClickHcfmSharePage">花城FM</div>
            <!-- <el-button type="default" @click="handleClickPlusSharePage"
              >花城+</el-button
            > -->
            <!-- <el-button type="default" @click="handleClickHcfmSharePage"
              >花城FM</el-button
            > -->
          </div>
          <!-- <div class="img-wrapper"></div> -->
          <div class="right-download" slot="reference">下载应用</div>
        </el-popover>
        <div class="right-complain" @click="showFeedBack = true">投诉意见</div>
      </div>
    </div>

    <div class="feedback" v-if="showFeedBack">
      <div class="content">
        <div class="close-icon">
          <i class="el-icon-close close" @click="showFeedBack = false"></i>
        </div>
        <div class="title">投诉举报</div>
        <div class="desc">举报电话：(020)89213303</div>
        <div class="desc">举报邮箱: guangshiwang@gztv.com</div>
        <a href="https://www.gdjubao.cn/jb/?harm_type=18" target="_blank"
          >涉未成年人网络有害信息举报专区</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import store from "vuex";
import events from "@/utils/call.js";
export default {
  name: "TopNav",
  data() {
    return {
      titles: ["首页", "专题", "要闻", "视频", "频道"],
      titleIndex: 0,
      showFeedBack: false,
      searchValue: "",
    };
  },
  // watch: {
  //   "$store.state.titleIndex": {
  //     handler(newVal) {
  //       this.titleIndex = newVal;
  //     },
  //     immediate: true,
  //   },
  // },
  mounted() {
    this.refreshPage();
    // events.$on("test", (test) => {
    //   console.log(test);
    // });

    events.$on("headerTab", (value) => {
      console.log("events.$on");
      if (value == "首页") {
        // this.handleClickTab("首页", 0);
      } else if (value == "专题") {
        // this.$router.push({
        //   name: "special",
        // });
        // this.handleClickTab("专题", 1);
        this.titleIndex = 1;
      } else if (value == "要闻") {
        // this.handleClickTab("要闻", 2);
        // this.$router.push({
        //   name: "focusNews",
        // });
        this.titleIndex = 2;
      } else if (value == "视频") {
        // this.$router.push({
        //   name: "videoPage",
        // });
        // this.handleClickTab("视频", 3);
        this.titleIndex = 3;
      } else if (value == "频道") {
        // this.$router.push({
        //   name: "channel",
        // });
        // this.handleClickTab("频道", 4);
        this.titleIndex = 4;
      } else if (value == "关于我们") {
        this.titleIndex = 11;
      } else if (value == "搜索") {
        this.titleIndex = 12;
      } else if (value == "专题全部详情") {
        this.titleIndex = 13;
      } else if (value == "专题标签详情") {
        this.titleIndex = 14;
      }
    });
  },
  methods: {
    refreshPage() {
      const _titleIndex = sessionStorage.getItem("titleIndex")
        ? sessionStorage.getItem("titleIndex")
        : 0;
      this.titleIndex = _titleIndex;
    },
    handleClickTab(item, index) {
      // console.log("handleClickTab", item, index);
      // let doms = document.getElementsByClassName("nav-active");
      // console.log("doms", doms);
      // if (!doms.length) {
      //   console.log("in", this.titleIndex, index);
      //   // document.getElementsByClassName("nav-item")[0].classList.add("nav-active");

      //   this.titleIndex = index;
      //   switch (item) {
      //     case "首页": {
      //       // document.getElementsByClassName("nav-item")[0].classList.add("nav-active");
      //       this.$router.push({
      //         name: "home",
      //       });
      //       break;
      //     }

      //     case "要闻": {
      //       this.$router.push({
      //         name: "focusNews",
      //       });
      //       break;
      //     }
      //     case "视频": {
      //       this.$router.push({
      //         name: "videoPage",
      //       });
      //       break;
      //     }
      //     case "频道": {
      //       this.$router.push({
      //         name: "channel",
      //       });
      //       break;
      //     }
      //     case "专题": {
      //       this.$router.push({
      //         name: "special",
      //       });
      //       break;
      //     }
      //   }
      //   return;
      // }

      // this.titleIndex = this.titles.findIndex((i) => i == item);

      if (this.titleIndex == index) {
        console.log("===return");
        return;
      }

      this.titleIndex = index;
      // sessionStorage.setItem("titleIndex", this.titleIndex);
      switch (item) {
        case "首页": {
          this.$router.push({
            name: "home",
          });
          break;
        }

        case "要闻": {
          this.$router.push({
            name: "focusNews",
          });
          break;
        }
        case "视频": {
          this.$router.push({
            name: "videoPage",
          });
          break;
        }
        case "频道": {
          this.$router.push({
            name: "channel",
          });
          break;
        }
        case "专题": {
          this.$router.push({
            name: "special",
          });
          break;
        }

        // case "公告": {
        //   this.$router.push({
        //     name: "notice",
        //   });
        //   break;
        // }
      }
    },
    handleClickPlusSharePage() {
      window.open("https://app.gztv.com/filedata/app/PLUSApp/downloadPage/plus_download.html");
    },
    handleClickHcfmSharePage() {
      window.open(
        // "https://load.gztv.com/filedata/app/HCFMApp/downloadPage/hcfm_download.html"
        "https://app.gztv.com/filedata/app/HCFMApp/downloadPage/hcfm_download.html"
      );
    },
    handleClickFeedback() {},
    handleClikcToHome() {
      console.log("handleClikcToHome");
      let doms = document.getElementsByClassName("nav-active");
      if (!doms.length) {
        // document.getElementsByClassName("nav-item")[0].classList.add("nav-active");
        this.titleIndex = 0;
        this.$router.push({
          name: "home",
        });
      } else {
        if (this.$route.name === "home") {
          console.log("handleClikcToHome", "回家");
          return;
        }
        this.titleIndex = 0;
        this.$router.push({
          name: "home",
        });
      }
    },
    handleSearchContent() {
      // console.log("搜索", this.searchValue, window.location.host);
      if (!this.searchValue) {
        return;
      }

      sessionStorage.setItem("searchValue", this.searchValue);
      // window.open(`https://gzbn.gztv.com:7443`);
      // http://localhost:8080
      if (process.env.NODE_ENV == "development") {
        window.open(`${window.location.protocol}//${window.location.host}/#/searchList`);
      } else if (process.env.NODE_ENV == "production") {
        window.open(`${window.location.protocol}//${window.location.host}/#/searchList`);
        // window.open(`https://gzbn.gztv.com:7443/#/searchList`);
      }
      // window.open();
      // this.id = this.$route.query.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";

.header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;

  .header-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 1200px;
    height: 100%;

    .logo {
      width: 55px;
      height: 55px;
      cursor: pointer;
    }

    .nav {
      display: flex;
      justify-content: space-between;
      margin-left: 93px;
      width: 404px;
      height: 100%;
      display: flex;
      align-items: center;

      .nav-item {
        width: 32px;
        height: 20px;
        cursor: pointer;
      }

      .nav-active {
        position: relative;
        color: $primary-color;

        &:after {
          position: absolute;
          content: "";
          width: 40px;
          height: 3px;
          bottom: -30px;
          left: -4px;
          border-radius: 2px;
          background: $primary-color;
        }
      }
    }

    .right-pane {
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;

      .right-download {
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }
      }

      .right-complain {
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 460px;
      height: 200px;
      background: #fff;
      border-radius: 6px;
      z-index: 999;
      box-sizing: border-box;
      padding: 10px 15px 30px 15px;

      .close-icon {
        position: relative;
        font-size: 18px;
        color: #000;
        width: 100%;
        font-weight: bold;

        .close {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }

      .title {
        font-weight: bold;
        font-size: 24px;
      }

      .desc {
        color: #333;
        font-size: 16px;
      }

      a {
        outline: none;
        color: blue;
      }
    }
  }
}
</style>

<style>
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  background: #f5f5f5;
  cursor: pointer;
  border-radius: 4px;
}

.download-btn:hover {
  background: rgba(231, 56, 40, 0.8);
  color: #fff;
}

.search-box {
  /* padding-left: 10px; */
  position: relative;
  right: 0;
  display: flex;
  flex-direction: row;

  height: 30px;
  border: 1px solid #ccc !important;
  /* border: none;

  /* outline: none; */
  border-radius: 200px;
  width: 205px;
  margin-right: 20px;
  align-items: center;
  position: relative;
  left: 200px;
}
.input-box {
  font-size: 15px;
  /* text-indent: 10px; */
  /* height: 28px; */
  padding-left: 10px;
  border: unset;
  outline: unset;
  width: 200px;
  border-radius: 200px;

  /*  */
  /* border: 1px solid #ccc !important; */
  /* outline: none; */
  /* border-left: 1px solid #ccc !important;
  border-radius: 200px; 
  width: 200px;

  /* opacity: 0; */

  /* &:focus {
    border-color: #409eff;
  } */
}
.btn-box {
  margin-right: 10px !important;
  color: #000000 !important;
  cursor: pointer;
}

.search-btn {
  /* border: none; */
  /* float: left; */
  box-sizing: border-box;
  cursor: pointer;
  height: 34px;
  left: -15px;
  position: relative;
  border-left: unset !important;
  border-right: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  /* border-bottom-right-radius: 20px;
  border-top-right-radius: 20px; */
  border-radius: 20px;
}
</style>
