import axios from "axios";

// console.log("process.env.NODE_ENV", process.env);
if (process.env.NODE_ENV == "development") {
  //axios.defaults.baseURL = "http://localhost:8888";
  //axios.defaults.baseURL = "https://gzbn.gztv.com:7443";
  axios.defaults.baseURL = "https://gzbn.gztv.com:7443/";
  //axios.defaults.baseURL = "https://gscb.kuaihd.com:7443";
  //axios.defaults.baseURL = "http://localhost:8888";
}

if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "https://gzbn.gztv.com:7443";
  // axios.defaults.baseURL = "https://gscb.kuaihd.com:7443";
}

// 设置超出时间
axios.defaults.timeout = 10000;

// 请求拦截
// axios.interceptors.request.use(config => {
//   config.headers = {
//     Authorization: ""
//   };
//   return config;
// });

// 封装get方法  并抛出
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// 封装post方法  并抛出
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

// 封装post方法  并抛出
export function postForm(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
