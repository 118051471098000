<template>
  <div class="myCarousel" v-loading="loading">
    <el-carousel :interval="5000" arrow="never">
      <el-carousel-item
        class="carousel-item"
        v-for="(item, index) in data"
        :key="index"
        @click.native="handleClickCarouselItem(item)"
      >
        <el-image
          style="width: 100%; height: 100%"
          :src="item.operatePictures ? item.operatePictures : item.pictureUrl"
          fit="cover"
        ></el-image>
        <div class="bottom-wrapper">
          {{ item.operateName }}
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { countClickContent } from "@/api/template";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    handleClickCarouselItem(item) {
      countClickContent({ operateId: item.operateId, operateType: item.operateType }).then(
        (res) => {
          console.log("MyCarousel");
        }
      );
      if (item.operateType == 1) {
        // 图文
        this.$router.push({
          name: "textPageDetail",
          query: {
            id: item.operateId,
          },
        });
      } else if (item.operateType == 2) {
        // 视频
        this.$router.push({
          name: "videoPageDetail",
          query: {
            id: item.operateId,
          },
        });
      } else if (item.operateType == 4) {
        // window.open(`https://www.gztv.com/#/specialDetail?id=${item.operateId}`);
        window.open(
          `${window.location.protocol}//${window.location.host}/#/specialDetail?id=${item.operateId}`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";

.myCarousel {
  width: 100%;
  height: 100%;
  position: relative;
  // background-color: turquoise;
  border-radius: 4px;
  overflow: hidden;
}
.el-carousel {
  height: 100%;
}

.bottom-wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  color: #fff;
  font-size: 20px;
  text-indent: 2px;
  letter-spacing: 2px;
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0.01));
}

.carousel-item {
  cursor: pointer;
}
</style>

<style>
.myCarousel .el-carousel__indicators--horizontal {
  position: absolute;
  right: 25px !important;
  left: unset !important;
  bottom: 21px !important;
}
.myCarousel .el-carousel__indicator {
  width: 8px;
  height: 8px;
  border-radius: 8px !important;
}
.myCarousel .el-carousel__indicator .el-carousel__button {
  width: 100%;
  height: 100%;
  border-radius: 8px !important;
}
.el-carousel__container {
  height: 100% !important;
}
</style>
