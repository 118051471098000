<template>
  <div class="row-video">
    <div class="img-wrapper" @click="handleClickItem(data)">
      <el-image class="bg-img" :src="handleImgUrl(data)" fit="cover"></el-image>
      <img class="play-img" src="@/assets/image/play.png" alt="" />
      <!-- <i class="el-icon-video-play play-img"></i> -->
    </div>
    <div class="content-wrapper">
      <div
        class="title text-line-2"
        :title="
          data.operateName
            ? data.operateName
            : data.articleTitle
            ? data.articleTitle
            : data.operateTitle
        "
        @click="handleClickItem(data)"
      >
        {{
          data.operateName
            ? data.operateName
            : data.articleTitle
            ? data.articleTitle
            : data.operateTitle
        }}
      </div>
      <div class="content-info">
        <div>
          {{ data.updateTime | formatMsgTime }}
          <span style="margin-left: 10px">
            <i class="el-icon-success" style="color: #e73828; margin-right: 5px"></i
            >{{ data.publishName }}
          </span>
        </div>
        <!-- <div>播放 {{ data.clickSum | fixNumToBig }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { countClickContent } from "@/api/template";

export default {
  name: "RowVideo",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleClickItem(item) {
      console.log("RowVideo", item);
      countClickContent({ operateId: item.operateId, operateType: item.operateType }).then(
        (res) => {
          console.log("countClickContent", res);
        }
      );
      if (item.operateType == 1) {
        // 图文
        this.$router.push({
          name: "textPageDetail",
          query: {
            id: item.operateId,
          },
        });
      } else if (item.operateType == 2) {
        // 视频
        this.$router.push({
          name: "videoPageDetail",
          query: {
            id: item.operateId,
          },
        });
      } else if (item.operateType == 4) {
        // console.log("专题", "专题");
        //专题
        // this.$router.push({
        //   name: "specialDetail",
        //   query: {
        //     id: item.operateId,
        //   },
        // });
        // window.open(`https://www.gztv.com/#/specialDetail?id=${item.operateId}`);
        window.open(
          `${window.location.protocol}//${window.location.host}/#/specialDetail?id=${item.operateId}`
        );
      } else if (item.operateType == 6) {
        // window.open(
        //   `${window.location.protocol}//${window.location.host}/#/channel?id=${item.operateId}`
        // );
        sessionStorage.setItem("channelOperateId", item.operateId);
        window.open(`${window.location.protocol}//${window.location.host}/#/channel`);
      }
    },
    handleImgUrl(data) {
      if (data.operatePictures) {
        let split1 = data.operatePictures.split(",");
        return split1[0];
      }
      if (data.pictureUrl) {
        let split2 = data.pictureUrl.split(",");
        return split2[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.row-video {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 384px;
  height: 274px;

  .img-wrapper {
    position: relative;
    width: 100%;
    height: 216px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .bg-img {
        transform: scale(1.1);
      }
      .play-img {
        width: 30px;
        height: 30px;
      }
    }

    .bg-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: rgba(0, 0, 0, 0.15);
      transition: transform 0.3s ease-in-out;
    }

    .play-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      z-index: 9;
      transition: all 0.3s ease-in-out;
    }
  }

  .content-wrapper {
    font-size: 16px;

    .title {
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }

    .content-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999;
      font-size: 14px;
    }
  }
}
</style>
